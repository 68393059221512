// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const Rails = require('@rails/ujs');
Rails.start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
const Graph = require('graphs');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'stylesheets/application';
import 'bootstrap';
import QRCode from 'qrcode';

document.addEventListener('turbolinks:load', function () {
  var x = document.getElementsByClassName('qr-code');
  var i;
  for (i = 0; i < x.length; i++) {
    QRCode.toDataURL(x[i].dataset.url, function (err, url) {
      x[i].src = url;
    });
  }

  var select = document.getElementById('inventory_level_history_inventory_level_id');
  if (select) {
    select.addEventListener('change', function (event) {
      Rails.ajax({
        url: '/inventory_levels/' + this.value,
        type: 'GET'
      });
    });
  }
});