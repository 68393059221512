document.addEventListener('turbolinks:load', () => {
  const stats = document.getElementById('stats');
  let statData = [];
  if (stats) {
    d3.json('daily_stats.json').then((data) => {
      statData = data;
      statData.map((x) => {
        x.inventory_deficit = x.inventory_manufactured - x.inventory_sold;

        if (x.inventory_required > x.inventory_level) {
          x.inventory_remaining_internal = x.inventory_required - x.inventory_level;
        } else {
          x.inventory_remaining_internal = 0;
        }

        if (x.inventory_required_external - x.inventory_level_external) {
          x.inventory_remaining_external = x.inventory_required_external - x.inventory_level_external;
        } else {
          x.inventory_remaining_external = 0;
        }
        x.inventory_required_total = x.inventory_required + x.inventory_required_external;
        x.inventory_remaining_total = x.inventory_remaining_internal + x.inventory_remaining_external;
        x.inventory_total = x.inventory_level_external + x.inventory_level;
      });

      modifyDataDaily(data);
    });
  }

  const ddl = document.getElementById('ddl');
  if (ddl) {
    ddl.addEventListener(
      'change',
      () => {
        const {
          value
        } = event.srcElement;

        if (!stats) {
          return;
        }

        switch (value) {
        case 'Daily':
          modifyDataDaily(statData);
          break;
        case 'Weekly':
          modifyDataWeekly(statData);
          break;
        case 'Monthly':
          modifyDataMonthly(statData);
          break;
        case 'Quarterly':
          modifyDataQuarterly(statData);
          break;
        }
      },
      false,
    );
  }

  const graphCheckboxes = document.getElementsByClassName('graph_checkbox');
  let i;
  for (i = 0; i < graphCheckboxes.length; i++) {
    graphCheckboxes[i].addEventListener(
      'change',
      () => {
        reDrawGraph();
      },
      false,
    );
  }
});

let dataMap = new Map();

function modifyDataDaily(data) {
  drawMultiGraph(data);
}

function modifyDataWeekly(data) {
  const week = d3.timeFormat('%Y%U');
  const nest = d3
    .nest()
    .key((d) => week(new Date(d.start_at)))
    .rollup((v) => ({
      inventory_level: Math.round(d3.mean(v, (d) => d.inventory_level)),
      inventory_level_external: Math.round(d3.mean(v, (d) => d.inventory_level_external)),
      inventory_manufactured: d3.sum(v, (d) => d.inventory_manufactured),
      inventory_required: Math.round(d3.mean(v, (d) => d.inventory_required)),
      inventory_required_external: Math.round(d3.mean(v, (d) => d.inventory_required_external)),
      inventory_required_total: Math.round(d3.mean(v, (d) => d.inventory_required_total)),
      inventory_sold: d3.sum(v, (d) => d.inventory_sold),
      inventory_deficit: d3.sum(v, (d) => d.inventory_deficit),
      inventory_remaining_internal: Math.round(d3.mean(v, (d) => d.inventory_remaining_internal)),
      inventory_remaining_external: Math.round(d3.mean(v, (d) => d.inventory_remaining_external)),
      inventory_remaining_total: Math.round(d3.mean(v, (d) => d.inventory_remaining_total)),
      inventory_total: Math.round(d3.mean(v, (d) => d.inventory_total))
    }))
    .entries(data);

  drawMultiGraph(
    nest.map((val) => {
      val.value.key = val.key;
      return val.value;
    }),
  );
}

function modifyDataMonthly(data) {
  const monthly = d3.timeFormat('%Y%m');
  const nest = d3
    .nest()
    .key((d) => monthly(new Date(d.start_at)))
    .rollup((v) => ({
      inventory_level: Math.round(d3.mean(v, (d) => d.inventory_level)),
      inventory_level_external: Math.round(d3.mean(v, (d) => d.inventory_level_external)),
      inventory_manufactured: d3.sum(v, (d) => d.inventory_manufactured),
      inventory_required: Math.round(d3.mean(v, (d) => d.inventory_required)),
      inventory_required_external: Math.round(d3.mean(v, (d) => d.inventory_required_external)),
      inventory_required_total: Math.round(d3.mean(v, (d) => d.inventory_required_total)),
      inventory_sold: d3.sum(v, (d) => d.inventory_sold),
      inventory_deficit: d3.sum(v, (d) => d.inventory_deficit),
      inventory_remaining_internal: Math.round(d3.mean(v, (d) => d.inventory_remaining_internal)),
      inventory_remaining_external: Math.round(d3.mean(v, (d) => d.inventory_remaining_external)),
      inventory_remaining_total: Math.round(d3.mean(v, (d) => d.inventory_remaining_total)),
      inventory_total: Math.round(d3.mean(v, (d) => d.inventory_total))
    }))
    .entries(data);
  drawMultiGraph(
    nest.map((val) => {
      val.value.key = val.key;
      return val.value;
    }),
  );
}

function modifyDataQuarterly(data) {
  const quarterly = d3.timeFormat('%Y%q');
  const nest = d3
    .nest()
    .key((d) => quarterly(new Date(d.start_at)))
    .rollup((v) => ({
      inventory_level: Math.round(d3.mean(v, (d) => d.inventory_level)),
      inventory_level_external: Math.round(d3.mean(v, (d) => d.inventory_level_external)),
      inventory_manufactured: d3.sum(v, (d) => d.inventory_manufactured),
      inventory_required: Math.round(d3.mean(v, (d) => d.inventory_required)),
      inventory_required_external: Math.round(d3.mean(v, (d) => d.inventory_required_external)),
      inventory_required_total: Math.round(d3.mean(v, (d) => d.inventory_required_total)),
      inventory_sold: d3.sum(v, (d) => d.inventory_sold),
      inventory_deficit: d3.sum(v, (d) => d.inventory_deficit),
      inventory_remaining_internal: Math.round(d3.mean(v, (d) => d.inventory_remaining_internal)),
      inventory_remaining_external: Math.round(d3.mean(v, (d) => d.inventory_remaining_external)),
      inventory_remaining_total: Math.round(d3.mean(v, (d) => d.inventory_remaining_total)),
      inventory_total: Math.round(d3.mean(v, (d) => d.inventory_total)),
    }))
    .entries(data);
  drawMultiGraph(
    nest.map((val) => {
      val.value.key = val.key;
      return val.value;
    }),
  );
}

function drawMultiGraph(data) {
  dataMap = new Map([
    ['key', ['key']],
    ['dates', ['dates']],
    ['inventory_level', ['Actual Inventory (Internal)']],
    ['inventory_level_external', ['Actual Inventory (External)']],
    ['inventory_total', ['Actual Inventory (Internal + External)']],
    ['inventory_required', ['Inventory Needed (Internal)']],
    ['inventory_required_external', ['Inventory Needed (External)']],
    ['inventory_manufactured', ['Units Completed']],
    ['inventory_sold', ['Units Sold']],
    ['inventory_deficit', ['Unit Deficit/Surplus']],
    ['inventory_remaining_internal', ['Inventory Gap (Internal)']],
    ['inventory_remaining_external', ['Inventory Gap (External)']],
    ['inventory_remaining_total', ['Inventory Gap (Internal + External)']],
    ['inventory_required_total', ['Inventory Required (Internal + External)']],
  ]);

  data.map((val) => {
    if (
      val.inventory_level &&
      val.inventory_level_external &&
      val.inventory_manufactured &&
      val.inventory_required &&
      val.inventory_required_external &&
      val.inventory_sold
    ) {
      if (val.key) dataMap.get('key').push(val.key);
      if (val.start_at) dataMap.get('dates').push(val.start_at);
      dataMap.get('inventory_level').push(val.inventory_level);
      dataMap
        .get('inventory_level_external')
        .push(val.inventory_level_external);
      dataMap.get('inventory_manufactured').push(val.inventory_manufactured);
      dataMap.get('inventory_required').push(val.inventory_required);
      dataMap
        .get('inventory_required_external')
        .push(val.inventory_required_external);
      dataMap.get('inventory_sold').push(val.inventory_sold);
      dataMap.get('inventory_deficit').push(val.inventory_deficit);
      dataMap.get('inventory_remaining_internal').push(val.inventory_remaining_internal);
      dataMap.get('inventory_remaining_external').push(val.inventory_remaining_external);
      dataMap.get('inventory_remaining_total').push(val.inventory_remaining_total);
      dataMap.get('inventory_required_total').push(val.inventory_required_total);
      dataMap.get('inventory_total').push(val.inventory_total);
    }
  });

  reDrawGraph();
}

function reDrawGraph() {
  drawBillboard(selectData());
}

function selectData() {
  const columns = [dataMap.get('dates'), dataMap.get('key')];

  if (document.getElementById('inventory_level_select').checked) {
    columns.push(dataMap.get('inventory_level'));
  }

  if (document.getElementById('inventory_level_external_select').checked) {
    columns.push(dataMap.get('inventory_level_external'));
  }

  if (document.getElementById('inventory_manufactured_select').checked) {
    columns.push(dataMap.get('inventory_manufactured'));
  }

  if (document.getElementById('inventory_required_select').checked) {
    columns.push(dataMap.get('inventory_required'));
  }

  if (document.getElementById('inventory_required_external_select').checked) {
    columns.push(dataMap.get('inventory_required_external'));
  }

  if (document.getElementById('inventory_sold_select').checked) {
    columns.push(dataMap.get('inventory_sold'));
  }

  if (document.getElementById('inventory_deficit_select').checked) {
    columns.push(dataMap.get('inventory_deficit'));
  }

  if (document.getElementById('inventory_remaining_internal_select').checked) {
    columns.push(dataMap.get('inventory_remaining_internal'));
  }

  if (document.getElementById('inventory_remaining_external_select').checked) {
    columns.push(dataMap.get('inventory_remaining_external'));
  }

  if (document.getElementById('inventory_remaining_total_select').checked) {
    columns.push(dataMap.get('inventory_remaining_total'));
  }

  if (document.getElementById('inventory_required_total_select').checked) {
    columns.push(dataMap.get('inventory_required_total'));
  }

  if (document.getElementById('inventory_total_select').checked) {
    columns.push(dataMap.get('inventory_total'));
  }

  return columns;
}

function drawBillboard(columns) {
  if (columns[0].length > 1) {
    drawTimeSeries(columns);
  } else {
    drawLineGraph(columns);
  }
}

function drawTimeSeries(columns) {
  const formatTime = d3.timeFormat('%B %d, %Y');
  const formatComma = d3.format(',');

  bb.generate({
    data: {
      x: 'dates',
      columns,
    },
    axis: {
      x: {
        type: 'timeseries',
        localtime: false,
      },
    },
    tooltip: {
      format: {
        title(d) {
          return formatTime(d);
        },
        value(d) {
          return formatComma(d);
        }
      },
    },
    zoom: {
      enabled: true,
    },
    bindto: '#multi_graph',
  });
}

function drawLineGraph(columns) {
  const formatComma = d3.format(',');

  bb.generate({
    data: {
      x: 'key',
      columns,
    },
    tooltip: {
      format: {
        value(d) {
          return formatComma(d);
        }
      },
    },
    zoom: {
      enabled: true,
    },
    bindto: '#multi_graph',
  });
}